.form {
  max-width: 480px;
}

.container {
  padding: 20px;
}

h1,
h2,
h3,
h4,
label,
input,
textarea,
div,
button {
  font-family: "Noto Sans Thai", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}